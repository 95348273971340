import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "CGI Commands for INSTAR VGA Cameras",
  "path": "/Advanced_User/CGI_Commands/VGA_Series_CGI_List/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Control your INSTAR VGA camera from your default web browser",
  "image": "./AU_SearchThumb_CGICommands_VGA.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_VGA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='IP vs CCTV' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='What’s The Difference Between Analog and IP Cameras?' image='/images/Search/AU_SearchThumb_Alexa.png' twitter='/images/Search/AU_SearchThumb_Alexa.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/CCTV_vs_IP/' locationFR='/fr/Advanced_User/CCTV_vs_IP/' crumbLabel="VGA" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "cgi-commands-for-instar-vga-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#cgi-commands-for-instar-vga-cameras",
        "aria-label": "cgi commands for instar vga cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CGI Commands for INSTAR VGA Cameras`}</h1>
    <h2 {...{
      "id": "complete-cgi-instruction-set-for-the-vga-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#complete-cgi-instruction-set-for-the-vga-series",
        "aria-label": "complete cgi instruction set for the vga series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Complete CGI Instruction Set for the VGA Series`}</h2>
    <p>{`For all developers that want to integrate our cameras in their software or for everyone that wants to experiment a little, we now offer a full list of all CGI commands that can be used with our IP cameras. Please understand that this list is only for advanced users who know what they are doing. We cannot give any technical support regarding our CGI commands.`}</p>
    <p>{`Click here to download the complete CGI command list:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Developer/INSTAR_CGI_-_IN-40xx_Decoder_Settings.pdf"
        }}>{`Complete PDF`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Developer/INSTAR_CGI_MJPEG_Chipset_English.pdf"
        }}>{`Addendum (IN-4010 v2 / IN-4011)`}</a></li>
    </ul>
    <h2 {...{
      "id": "list-of-the-most-needed-cgi-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#list-of-the-most-needed-cgi-commands",
        "aria-label": "list of the most needed cgi commands permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`List of the most needed CGI commands`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#video"
        }}>{`Video`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#snapshot"
        }}>{`Snapshot`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#video--audio"
        }}>{`Video & Audio`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#configuration"
        }}>{`Configuration`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#pan--tilt"
        }}>{`Pan & Tilt`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#automatic-patrol"
        }}>{`Automatic Patrol`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#alarm"
        }}>{`Alarm`}</a></li>
    </ol>
    <hr></hr>
    <h3 {...{
      "id": "video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#video",
        "aria-label": "video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`/videostream.cgi`}{`[?user=&pwd=&resolution=&rate=]`}</strong></p>
    </blockquote>
    <ul>
      <li parentName="ul"><strong parentName="li">{`/videostream.cgi?`}</strong>{` | resolution: (8：320`}{`*`}{`240，32：640`}{`*`}{`480)`}
        <ul parentName="li">
          <li parentName="ul">{`rate： 0-23`}</li>
          <li parentName="ul">{`0：highest/fastest`}</li>
          <li parentName="ul">{`1：20 fps`}</li>
          <li parentName="ul">{`3：15 fps`}</li>
          <li parentName="ul">{`6：10 fps`}</li>
          <li parentName="ul">{`11：5 fps`}</li>
          <li parentName="ul">{`12：4 fps`}</li>
          <li parentName="ul">{`13：3 fps`}</li>
          <li parentName="ul">{`14：2 fps`}</li>
          <li parentName="ul">{`15：1 fps`}</li>
          <li parentName="ul">{`17：1 fp/2s`}</li>
          <li parentName="ul">{`19：1 fp/3s`}</li>
          <li parentName="ul">{`21：1 fp/4s`}</li>
          <li parentName="ul">{`23：1 fp/5s`}</li>
        </ul>
      </li>
    </ul>
    <p>{`For example: If your IP is 192.168.1.190 and Port is 81 and you want a resolution of 640*480 and the fastest speed of fps then you have to type in the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.190:81/videostream.cgi?user=admin&pwd=&resolution=32&rate=0`}</code></pre></div>
    <hr></hr>
    <h3 {...{
      "id": "snapshot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#snapshot",
        "aria-label": "snapshot permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Snapshot`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`/snapshot.cgi`}{`[?user=&pwd=&next`}{`_`}{`url=]`}</strong></p>
    </blockquote>
    <p>{`After next`}{`_`}{`url the user can type in the name of the picture. If you dont include next_url then the picture will automaticly get the name (DeviceAlias + current time.jpg)`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.190:81/snapshot.cgi?user=admin&pwd=
http://192.168.1.190:81/snapshot.cgi?user=admin&pwd=&next_url=test`}</code></pre></div>
    <hr></hr>
    <h3 {...{
      "id": "video--audio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#video--audio",
        "aria-label": "video  audio permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video & Audio`}</h3>
    <p>{`Settings are the same as videostream.cgi above.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.190:81/videostream.asf?user=admin&pwd=&resolution=32&rate=0`}</code></pre></div>
    <hr></hr>
    <h3 {...{
      "id": "configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuration",
        "aria-label": "configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`/camera`}{`_`}{`control.cgi?param=&value=`}{`[&user=&pwd=&next`}{`_`}{`url=]`}</strong></p>
    </blockquote>
    <ul>
      <li parentName="ul"><strong parentName="li">{`/camera`}{`_`}{`control.cgi?`}</strong>{` | RESOLUTION: 2 ： qqvga, 8 ： qvga, 32 ： vga`}
        <ul parentName="li">
          <li parentName="ul">{`BRIGHTNESS: 0~255`}</li>
          <li parentName="ul">{`CONTRAST: 0~6`}</li>
          <li parentName="ul">{`MODE: 0 ： 50hz, 1 ： 60hz, 2 ： outdoor`}</li>
          <li parentName="ul">{`ROTATION: 0 ： Default, 1 ： Flip Vertical, 2 ： Level Image, 3 ： Flip + horizontal Mirror Image`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "pan--tilt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pan--tilt",
        "aria-label": "pan  tilt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pan & Tilt`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`/decoder`}{`_`}{`control.cgi?command=`}{`[&onestep=&user=&pwd=&next`}{`_`}{`url=]`}</strong></p>
    </blockquote>
    <ul>
      <li parentName="ul"><strong parentName="li">{`/decoder`}{`_`}{`control.cgi?`}</strong>{` | tilt up="/decoder_control.cgi?command=0&onestep=1&user=&pwd="`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`tilt down`}</strong>{`: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=2&onestep=1&user=&pwd=`}</code></li>
          <li parentName="ul"><strong parentName="li">{`pan left`}</strong>{`: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=4&onestep=1&user=&pwd=`}</code></li>
          <li parentName="ul"><strong parentName="li">{`pan right`}</strong>{`: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=6&onestep=1&user=&pwd=`}</code></li>
          <li parentName="ul"><strong parentName="li">{`home pos`}</strong>{`: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=25`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`For a direct position change use:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`/decoder`}{`_`}{`control.cgi?command=`}{`[&onestep=&user=&pwd=&next`}{`_`}{`url=]`}</strong></p>
    </blockquote>
    <ul>
      <li parentName="ul"><strong parentName="li">{`/decoder`}{`_`}{`control.cgi?`}</strong>{` | Pos01 = /decoder_control.cgi?command=31&user=&pwd=`}
        <ul parentName="li">
          <li parentName="ul">{`Pos02: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=33&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos03: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=35&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos04: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=37&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos05: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=39&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos06: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=41&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos07: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=43&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos08: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=45&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos09: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=47&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos10: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=49&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos11: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=51&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos12: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=53&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos13: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=55&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos14: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=57&user=&pwd=`}</code></li>
          <li parentName="ul">{`Pos15: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/decoder_control.cgi?command=59&user=&pwd=`}</code></li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "automatic-patrol",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#automatic-patrol",
        "aria-label": "automatic patrol permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Automatic Patrol`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`/set`}{`_`}{`misc.cgi?`}{`[led`}{`_`}{`mode=&ptz`}{`_`}{`center`}{`_`}{`onstart=&ptz`}{`_`}{`auto`}{`_`}{`patrol`}{`_`}{`interval=&ptz`}{`_`}{`auto`}{`_`}{`patrol`}{`_`}{`type=&ptz`}{`_`}{`patrol `}{`_`}{`h`}{`_`}{`rounds=&ptz`}{`_`}{`patrol`}{`_`}{`v`}{`_`}{`rounds=&user=&pwd=&next`}{`_`}{`url=]`}</strong></p>
    </blockquote>
    <ul>
      <li parentName="ul"><strong parentName="li">{`/set`}{`_`}{`misc.cgi?`}</strong>{` | ptz`}{`_`}{`center`}{`_`}{`onstart：=1，activate center on start`}
        <ul parentName="li">
          <li parentName="ul">{`ptz`}{`_`}{`auto`}{`_`}{`patrol`}{`_`}{`interval：automatic patrol，=0：no automatic control`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`auto`}{`_`}{`patrol`}{`_`}{`type：0：No；1：horizontal；2：vertical；3：horizontal and vertical`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`h`}{`_`}{`rounds：Horizontal patrol laps，0：Infinite`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`v`}{`_`}{`rounds：Vertical patrol laps，0：Infinite`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`rate：Head Speed manual，0-100，0：Fastest`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`up`}{`_`}{`rate：automatic cruise speed (up)：0-100，0：slowest`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`down`}{`_`}{`rate：automatic cruising speed (down)：0-100，0：slowest`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`left`}{`_`}{`rate：cruising speed automatic (left)：0-100，0：slowest`}</li>
          <li parentName="ul">{`ptz`}{`_`}{`patrol`}{`_`}{`right`}{`_`}{`rate：cruising speed automatic (right) ：0-100，0：slowest`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm",
        "aria-label": "alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm`}</h3>
    <p>{`If you want to activate or deactivate the alarm function please enter the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.196/set_alarm.cgi?motion_armed=1&user=admin&pwd=`}</code></pre></div>
    <p>{`(Alarm activated)`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.196/set_alarm.cgi?motion_armed=0&user=admin&pwd=`}</code></pre></div>
    <p>{`(Alarm deactivated)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      